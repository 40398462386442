import { graphql } from "gatsby";
import * as React from "react";
import { GatsbyImageType, SEO, ThemePRO } from "../util/types";
import Seo from "../components/seo";
import Layout from "../components/layout";
import CategoryHeader from "../components/categoryheader";
import Title from "../components/title";
import ThemeCard from "../components/themecard";
import Carousel from "../components/carousel";
import { Types } from "../components/illustration-bg";

export interface IndexPageProps {
  data: {
    themes: {
      all: Array<ThemePRO>;
    };
    page: {
      title: string;
      SEO: SEO;
      sliders: Array<{
        title: string;
        illustration: { localFile: GatsbyImageType };
      }>;
    };
  };
}

const IndexPage: React.FC<IndexPageProps> = ({
  data: {
    page: { title, SEO, sliders },
    themes: { all: themes },
  },
}) => {
  return (
    <>
      <Layout>
        <Seo {...SEO} />
        <main className="md:mt-20">
          {/* Cover */}
          <Header sliders={sliders} />

          <section
            className={
              "mt-20 mb-48 px-5 md:px-15 lg:mb-64 max-w-[1400px] mx-auto"
            }
          >
            <Title>{title}</Title>
            <div
              className={`mt-10 grid gap-15
                    md:grid-cols-2 
                    lg:grid-cols-3
                    xl:gap-24`}
            >
              {themes.map((theme, i) => (
                <ThemeCard theme={theme} key={theme.id} style={Types[i % 3]} />
              ))}
            </div>
          </section>
        </main>
      </Layout>
    </>
  );
};

const Header: React.FC<{
  sliders: Array<{
    title: string;
    illustration: { localFile: GatsbyImageType };
  }>;
}> = ({ sliders }) => {
  return (
    <>
      <header className={"mt-8 relative lg:mt-0"}>
        <Carousel
          animation={"fade"}
          numItemsOnSmallTablet={1}
          numItemsOnPhone={1}
          numItemsOnTablet={1}
          numItemsOnLaptop={1}
          numItemsOnPC={1}
          showDots={false}
          renderStatic={(currentPage, maxPage, jump) => (
            <>
              {/* The Shadow of the wave */}
              <BlueWave
                className="absolute
               left-0 right-0 fill-current text-gray-800/10 blur-sm
               hidden
               lg:block
               md:-bottom-5
               lg:-bottom-7
               xl:-bottom-12
               xxl:-bottom-12
               "
              />

              {/* The wave */}
              <BlueWave
                className="absolute
                left-0 right-0 fill-current
                text-secondary-100
                hidden
                md:block
                md:-bottom-3
                lg:-bottom-5
                xl:-bottom-10
                xxl:-bottom-10
                "
              />

              {/* The shadow of wave on mobile */}
              <BlueWaveMobile
                className="absolute
               left-0 right-0 fill-current text-gray-800/10 blur-sm
               -bottom-8
               sm:-bottom-4
               md:hidden
               "
              />

              {/* The wave on mobile */}
              <BlueWaveMobile
                className="absolute
                left-0 right-0 fill-current text-secondary-100
                -bottom-6
                sm:-bottom-10
                md:hidden
                "
              />

              {/* The Slider buttons indicators */}
              {maxPage > 1 && (
                <div
                  className={`absolute
               left-0 right-0 
               flex 
               space-x-4 
               mt-4 
               justify-center
               -bottom-2
               sm:-bottom-1
               lg:-bottom-2
               xl:-bottom-5
               xxl:-bottom-4`}
                >
                  {Array(maxPage)
                    .fill(1)
                    .map((_, index) => (
                      <button
                        aria-label={`Aller à la page ${index + 1}`}
                        key={index}
                        onClick={() => {
                          jump(index + 1);
                        }}
                        className={`rounded-full h-3 w-3 ${
                          index + 1 === currentPage
                            ? "bg-white"
                            : "bg-light-100"
                        }`}
                      />
                    ))}
                </div>
              )}
            </>
          )}
        >
          {sliders.map((slider, index) => (
            <CategoryHeader
              key={index}
              title={slider.title}
              cover={slider.illustration}
            />
          ))}
        </Carousel>
      </header>
    </>
  );
};

const BlueWave: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    data-name="Calque 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1227 61.41"
    {...props}
  >
    <path
      d="M-3.1 25.1s382.4 4.8 714.7-5.6c106.3-4 275.4-1.4 570.4-2.1v39.5S947.3 50 558.4 63.3C409.6 66.9-6 65.9-6 65.9s.1-42.1 2.9-40.8z"
      fill="currentColor"
    />
  </svg>
);

const BlueWaveMobile: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <>
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 376.8 50.8">
      <path
        d="M-.7 16.7s112.5 3.2 210.3-3.7c31.3-2.6 81-.9 167.8-1.4v26s-98.5-4.6-212.9 4.2c-43.7 2.4-166 1.8-166 1.8s0-27.7.8-26.9z"
        fill="currentColor"
      />
    </svg>
  </>
);

export default IndexPage;
export const query = graphql`
  query PagePRO {
    themes: allStrapiThemesPro {
      all: nodes {
        id
        SEO {
          slug
        }
        Home {
          description
          title
          illustration {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
    }
    page: strapiPagePro {
      title
      SEO {
        description
        title
      }
      sliders: slider {
        title
        illustration {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
        }
      }
    }
  }
`;
