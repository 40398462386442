import * as React from "react";

export const Types = ["first", "second", "third"] as const;

export interface IllustrationBgProps {
  style?: typeof Types[number];
  className?: string;
}

const IllustrationBg: React.FC<IllustrationBgProps> = ({
  style,
  className = "",
}) => {
  const mapping: Record<
    typeof Types[number],
    React.FC<{ className: string }>
  > = {
    first: props => <FirstSvgIllustrationBg {...props} />,
    second: props => <SecondSvgIllustrationBg {...props} />,
    third: props => <ThirdSvgIllustrationBg {...props} />,
  };

  const Svg = mapping[style];

  return <Svg className={className} />;
};

export default IllustrationBg;

const FirstSvgIllustrationBg: React.FC<{ className?: string }> = ({
  className = "",
}) => {
  return (
    <svg
      className={`${className} fill-current`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 222.9 188.3"
    >
      <path
        d="M170.1 172.7c38.3-22.3 62.8-68.2 48.9-103.1-6.8-17.1-21.2-27.8-42.7-36.7-17.1-7.1-39-13-65.1-20-44.6-12-66.9-18-83.9-7.6-39.8 24.3-40.3 131.4 26.8 169 31.9 17.8 78.9 20 116-1.6z"
        fill="currentColor"
      />
    </svg>
  );
};

const SecondSvgIllustrationBg: React.FC<{ className?: string }> = ({
  className = "",
}) => {
  return (
    <svg
      className={`${className} fill-current`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 222.9 188.3"
    >
      <path
        d="M6.1 102c-3.6 22-7.7 47.4 6.6 60.7 20 18.6 66.6 4.8 73.8 2.7 13.3-3.9 18.9-8 32.3-8.2 19.4-.3 26.5 8.1 39.4 9.3 26.4 2.5 61.2-26.5 63.4-51.9 4.5-51-118.1-131.5-176.1-94.6-29 18.4-36.3 62.6-39.4 82z"
        fill="currentColor"
      />
    </svg>
  );
};

const ThirdSvgIllustrationBg: React.FC<{ className?: string }> = ({
  className = "",
}) => {
  return (
    <svg
      className={`${className} fill-current`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 222.9 188.3"
    >
      <path
        d="M76.1 187.4c16.9-.4 18.2-19.7 50.9-35.3 23.7-11.3 31.8-5.2 50.4-17.3 2.4-1.5 30.3-20.2 32.8-43.3 4.3-40.8-69.6-106.3-131.4-83C37.7 24 16.4 72.8 21.4 113.4c4.3 36.1 30.6 74.6 54.7 74z"
        fill="currentColor"
      />
    </svg>
  );
};
