import * as React from "react";
import { ThemePRO } from "../util/types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Card from "./card";
import IllustrationBg, { Types } from "./illustration-bg";

export interface ThemeCardProps {
  theme: ThemePRO;
  style?: typeof Types[number];
}

const ThemeCard: React.FC<ThemeCardProps> = ({
  style = "first",
  theme: {
    SEO: { slug },
    Home: { title, description, illustration },
  },
}) => {
  return (
    <Card
      as={"Link"}
      animated
      href={`/${slug}`}
      className={`text-center flex-col space-y-6`}
    >
      <h2 className={"text-secondary-100 uppercase font-bold"}>{title}</h2>
      <div className={"relative h-40 flex justify-center items-center"}>
        <IllustrationBg
          style={style}
          className={`m-auto h-full text-light-50
          transition-colors  
          duration-300 
          ease-in-out  
          group-hover:text-light-100
          group-active:text-light-100
          `}
        />

        {illustration.localFile?.childImageSharp ? (
          <GatsbyImage
            image={getImage(illustration.localFile.childImageSharp)}
            alt={title}
            className="
              absolute
              object-contain m-auto h-20 opacity-50
              transition-opacity
              duration-300
              ease-in-out
              opacity-60
              group-hover:opacity-100
              group-active:opacity-100
              "
          />
        ) : (
          <img
            src={illustration.localFile?.publicURL}
            alt={title}
            className="
              absolute
              object-contain m-auto h-20
              transition-opacity
              duration-300
              ease-in-out
              opacity-60
              group-hover:opacity-100
              group-active:opacity-100
            "
          />
        )}
      </div>
      <p className={"text-left "}>{description}</p>
    </Card>
  );
};

export default ThemeCard;
