import { Link } from "gatsby";
import * as React from "react";

export const elementTypes = [
  "div",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "li",
] as const;

export interface HeadTitleProps {
  label: string;
  className?: string;
  as?: typeof elementTypes[number];
  href?: string;
  breakWords?: boolean;
  thin?: boolean;
}

const HeadTitle: React.FC<HeadTitleProps> = ({
  breakWords,
  href,
  as,
  label,
  className = "",
  thin = false,
}) => {
  let first: string = label;
  let second: string = null;

  if (breakWords) {
    [first, second] = breakWordInTwo(label);
  }

  return (
    <HeadTitleWrapper
      as={as}
      className={`${className} ${
        breakWords && "transform -rotate-3 flex flex-col space-y-2 max-w-max"
      }`}
    >
      {breakWords ? (
        <>
          <span
            className={`${
              !thin && "font-bold"
            } -skew-x-3 text-white bg-secondary-100 px-1 self-start shadow-md`}
          >
            {first}
          </span>
          {second && (
            <div className="self-end relative w-full">
              <span
                className={`-skew-x-3 ${!thin && "font-bold"}
                  text-white bg-primary-100 px-1 absolute left-1/3 whitespace-nowrap shadow-md
                  sm:left-1/2
                  `}
              >
                {second}
              </span>
            </div>
          )}
        </>
      ) : (
        <Link to={href}>
          <span
            className={`${
              !thin && "font-bold"
            } text-white bg-secondary-100 px-1 ${
              href && `hover:bg-dark-100 group-hover:bg-dark-100`
            }`}
          >
            {label}
          </span>
        </Link>
      )}
    </HeadTitleWrapper>
  );
};

const breakWordInTwo = (words: string): [string, string] => {
  const splitted = words.trim().split(" ");

  const firstSplitLength = Math.ceil(splitted.length / 2);

  const first = splitted.slice(0, firstSplitLength).join(" ");
  const second = splitted.slice(firstSplitLength).join(" ");

  return [first, second];
};

// The parent element to wrap with
const HeadTitleWrapper: React.FC<{
  as: typeof elementTypes[number];
  className?: string;
}> = ({ as, children, className = "" }) => {
  const elementFC = {
    div: props => <div {...props} />,
    h1: props => <h1 {...props} />,
    h2: props => <h2 {...props} />,
    h3: props => <h3 {...props} />,
    h4: props => <h4 {...props} />,
    h5: props => <h5 {...props} />,
    h6: props => <h6 {...props} />,
    li: props => <li {...props} />,
  };
  const HeadValue = elementFC[as];
  return <HeadValue className={className}>{children}</HeadValue>;
};

export default HeadTitle;
